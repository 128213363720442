import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Layout from './Layout';
import Home from './Home';
import Class from './Class';
import Navbar from './Navbar';
import Location from './Location';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route navbar element={<Navbar />} />
          <Route location element={<Location />} />
          <Route path="class" element={<Class />} />
        </Route>
      </Routes>
    </BrowserRouter>
);