import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ClassOne from "./ClassOne";
import ClassTwo from "./ClassTwo";
import ClassThree from "./ClassThree";
import 'react-tabs/style/react-tabs.css';

function Class() {
    return (
        <div className="inner">
            <Tabs>
                <div id="blockName">
                    <h5 className="title">課程介紹</h5>
                    <TabList>
                        <Tab>光復館</Tab>
                        <Tab>公館館</Tab>
                        <Tab>新竹館</Tab>
                    </TabList>
                </div>
                <TabPanel>
                    <div className="left">
                        <h2><b>代停課</b>公告</h2>
                        <ul>
                            <li><b>12/31 <i>(六)</i></b> 跨年 休館一日</li>
                            <li><b>01/01 <i>(日)</i></b> 跨年 休館一日</li>
                            <li><b>01/20 <i>(五)</i></b> 過年期間 1/20日至1/29 暫停開放</li>
                            <li>歡歡的課 停到年底</li>
                        </ul>
                    </div>
                    <div className="right">
                        <ul className="level">
                            <li><b className="started">基礎課程</b></li>
                            <li><b className="normal">一般課程</b></li>
                            <li><b className="adv">進階課程</b></li>
                        </ul>
                        <table className="classTable">
                            <thead>
                                <tr>
                                    <th rowSpan="2"></th>
                                    <th colSpan="2">一</th>
                                    <th colSpan="2">二</th>
                                    <th colSpan="2">三</th>
                                    <th colSpan="2">四</th>
                                    <th colSpan="2">五</th>
                                    <th colSpan="2">六</th>
                                    <th colSpan="2">日</th>
                                </tr>
                                <tr>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>16:30<br />|<br />18:00</th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><h2 className="normal">House</h2><h4>牛牛</h4></td>
                                    <td><h2 className="normal">Hip-Hop</h2><h4>胡婷</h4></td>
                                </tr>
                                <tr>
                                    <th>18:30<br />|<br />20:00</th>
                                    <td><h2 className="adv">House</h2><h4>小穎</h4></td>
                                    <td><h2 className="normal">Waacking</h2><h4>金瑋瑋</h4></td>
                                    <td><h2 className="normal">High Heels</h2><h4>宋靜</h4></td>
                                    <td><h2 className="normal">Hip-Hop</h2><h4>Mark</h4></td>
                                    <td><h2 className="normal">Afro Freestyle</h2><h4>小姬</h4></td>
                                    <td></td>
                                    <td><h2 className="adv">FreeStyle</h2><h4>卡拉</h4></td>
                                    <td>
                                        <h2 className="normal">Girl Style</h2><h4>冉宥</h4>
                                    </td>
                                    <td>
                                        <h2 className="adv">Hip-Hop</h2>
                                        <h4>家延</h4></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><h2 className="normal">Choreography</h2><h4>姿儀</h4></td>
                                    <td>
                                        <h2 className="adv">Dancehall</h2>
                                        <h4>小南<br /></h4>
                                    </td>
                                </tr>
                                <tr>
                                    <th>20:15<br />|<br />21:45</th>
                                    <td><h2 className="normal">Hip-Hop</h2><h4>Ben</h4></td>
                                    <td><h2 className="normal">Choreography</h2><h4>七七</h4></td>
                                    <td><h2 className="adv">Dancehall</h2><h4>小姬</h4></td>
                                    <td><h2 className="adv">Hip-Hop</h2><h4>查理</h4></td>
                                    <td><h2 className="normal">Hip-Hop</h2><h4>昱豪</h4></td>
                                    <td><h2 className="normal">Popping</h2><h4>龔妹</h4></td>
                                    <td><h2 className="normal">Locking</h2><h4>鄭妹</h4></td>
                                    <td><h2 className="normal">Choreography</h2><h4>小林</h4></td>
                                    <td><h2 className="normal">FreeStyle</h2><h4>亨利</h4></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>20:30<br />|<br />22:00</th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <img src={require('./assets/images/c-title.png')} className="cTitle" alt='教室位置' />
                        <table className="classTable">
                            <thead>
                                <tr>
                                    <th width="47"></th>
                                    <th width="105">一</th>
                                    <th width="107">二</th>
                                    <th width="95">三</th>
                                    <th width="99">四</th>
                                    <th width="99">五</th>
                                    <th width="95"></th>
                                    <th width="95">六</th>
                                    <th width="127">日</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th></th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th>11:00</th>
                                    <td><h2 className="started">幼兒律動</h2><h4>青軒</h4></td>
                                    <td><h2 className="started">幼兒律動</h2><h4>若若</h4></td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th>12:00</th>
                                    <td><h4>午休</h4></td>
                                    <td><h4>午休</h4></td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th>13:30</th>
                                    <td><h2 className="started">MV</h2><h4>青軒</h4></td>
                                    <td><h2 className="started">Popping</h2><h4>龔妹</h4></td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th>14:45</th>
                                    <td><h2 className="started">Hip-Hop</h2><h4>Ben</h4></td>
                                    <td><h2 className="started">Hip-Hop</h2><h4>姿儀</h4></td>
                                </tr>
                            </tbody>
                        </table>
                        <ClassOne />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="left">
                        <h2><b>代停課</b>公告</h2>
                        <ul>
                            <li><b>10/09<i>(日)</i> </b>目前無代停課</li>
                        </ul>
                    </div>
                    <div className="right">
                        <ul className="level">
                            <li><b className="started">基礎課程</b></li>
                            <li><b className="normal">一般課程</b></li>
                            <li><b className="adv">進階課程</b></li>
                        </ul>
                        <table className="classTable">
                            <thead>
                                <tr>
                                    <th rowSpan="2"></th>
                                    <th colSpan="2">一</th>
                                    <th colSpan="2">二</th>
                                    <th colSpan="2">三</th>
                                    <th colSpan="2">四</th>
                                    <th colSpan="2">五</th>
                                    <th colSpan="2">六</th>
                                    <th colSpan="2">日</th>
                                </tr>
                                <tr>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>14:30<br />|<br />16:00</th><td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><h2 className="normal">Waacking</h2><h4>歐陽</h4></td>
                                    <td><h2 className="adv">Dancehall</h2><h4>黑嘉麗</h4></td>
                                    <td colSpan="2"><h2 className="started">兒童街舞<br />(7歲以上)</h2><h4>小龍女</h4><h4>14：30~15：30</h4></td>
                                </tr>
                                <tr>
                                    <th>16:30<br />|<br />18:00</th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><h2 className="normal">Locking</h2><h4>Swag</h4></td>
                                    <td><h2 className="normal">Hip-Hop</h2><h4>可白</h4></td>
                                    <td><h2 className="started">基礎律動</h2><h4>阿睿</h4></td>
                                    <td><h2 className="started">Girl Style</h2><h4>默默</h4></td>
                                </tr>
                                <tr>
                                    <th>18:30<br />|<br /> 20:00</th>
                                    <td><h2 className="normal">Hip-Hop</h2><h4>Mark</h4></td>
                                    <td><h2 className="started">Dancehall</h2><h4>小喵</h4></td>
                                    <td><h2 className="normal">Locking</h2><h4>菜菜</h4></td>
                                    <td><h2 className="normal">Girl Style</h2><h4>小捲</h4></td>
                                    <td><h2 className="normal">Girl Style</h2><h4>曲莉</h4></td>
                                    <td><h2 className="started">Hip-Hop</h2><h4>中中</h4></td>
                                    <td><h2 className="normal">Dancehall</h2><h4>金瑋瑋</h4></td>
                                    <td><h2 className="started">Hip-Hop</h2><h4>奶茶</h4></td>
                                    <td><h2 className="started">Girl Style</h2><h4>虹宇</h4></td>
                                    <td><h2 className="started">Locking</h2><h4>冠瑒</h4></td>
                                    <td></td>
                                    <td><h2 className="normal">Popping</h2><h4>小樂</h4></td>
                                    <td><h2 className="started">Waacking</h2><h4>雅琦</h4></td>
                                    <td><h2 className="started">Hip-Hop</h2><h4>小龍女</h4></td>
                                </tr>
                                <tr>
                                    <th>20:30<br />|<br />22:00</th>
                                    <td><h2 className="normal">House</h2><h4>陳學耀</h4></td>
                                    <td><h2 className="started">Oldway<br />vogueing</h2><h4>Old Way<br />234</h4></td>
                                    <td><h2 className="normal">Voguing</h2><h4>A-Yao</h4></td>
                                    <td><h2 className="started">Hip-Hop</h2><h4>青軒</h4></td>
                                    <td><h2 className="normal">House</h2><h4>JuAnn</h4></td>
                                    <td><h2 className="started">Waacking</h2><h4>芭比</h4></td>
                                    <td><h2 className="adv">Popping</h2><h4>雪球</h4></td>
                                    <td><h2 className="started">Dancehall</h2><h4>涵涵</h4></td>
                                    <td><h2 className="normal">Afro Style</h2><h4>巴豪</h4></td>
                                    <td><h2 className="normal">Popping</h2><h4>小胖</h4></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <ClassTwo />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="left">
                        <h2><b>代停課</b>公告</h2>
                        <ul>
                            <li><b>07/27</b>目前無代停課</li>
                        </ul>
                    </div>
                    <div className="right">
                        <ul className="level three">
                            <li><b className="started">基礎課程</b></li>
                            <li><b className="normal">一般課程</b></li>
                            <li><b className="adv">進階課程</b></li>
                        </ul>
                        <table className="classTable three">
                            <thead>
                                <tr>
                                    <th rowSpan="2"></th>
                                    <th colSpan="2">一</th>
                                    <th colSpan="2">二</th>
                                    <th colSpan="2">三</th>
                                    <th colSpan="2">四</th>
                                    <th colSpan="2">五</th>
                                    <th></th>
                                    <th colSpan="2">六</th>
                                    <th colSpan="2">日</th>
                                </tr>
                                <tr>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                    <td colSpan="2"><div>A</div><div>B</div></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th></th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>12:30<br />|<br />14:00</td>
                                    <td><h2 className="normal">Street Jazz</h2><h4>伊娜</h4></td>
                                    <td><h2 className="started">基礎律動</h2><h4>書洋</h4></td>
                                    <td><h2 className="started">基礎律動</h2><h4>小智</h4></td>
                                    <td><h2 className="started">Waacking</h2><h4>卓櫻</h4></td>
                                </tr>
                                <tr>
                                    <th>16:30<br />|<br />18:00</th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>14:00<br />|<br />15:30</td>
                                    <td><h2 className="started">House</h2><h4>書洋</h4></td>
                                    <td><h2 className="normal">Locking</h2><h4>古呈允</h4></td>
                                    <td><h2 className="normal">New Jazz</h2><h4>兔兔</h4></td>
                                    <td><h2 className="started">Hip-Hop</h2><h4>小智</h4></td>
                                </tr>
                                <tr>
                                    <th>18:30<br />|<br />20:00</th>
                                    <td><h2 className="started">TopRock</h2><h4>阿雅</h4></td>
                                    <td><h2 className="started">Locking</h2><h4>翔A</h4></td>
                                    <td><h2 className="normal">Hip-Hop</h2><h4>阿睿</h4></td>
                                    <td><h2 className="started">Street Jazz</h2><h4>coco</h4></td>
                                    <td><h2 className="started">Popping</h2>
                                        <h4>阿棠</h4></td>
                                    <td><h2 className="started">Dancehall<br />Afro</h2><h4>宇麟</h4></td>
                                    <td><h2 className="started">基礎律動</h2><h4>小JOY</h4></td>
                                    <td><h2 className="started">Street Jazz</h2><h4>沐依</h4></td>
                                    <td><h2 className="started">New Jazz</h2><h4>兔兔</h4></td>
                                    <td><h2 className="started">Popping</h2><h4>雪球</h4></td>
                                    <td>15:30<br />|<br />17:00</td>
                                    <td><h2 className="started">基礎律動</h2><h4>小茜</h4></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>20:00<br />|<br />21:30</td>
                                    <td><h2 className="started">Hip-Hop</h2><h4>浩浩</h4></td>
                                    <td><h2 className="started">基礎律動</h2><h4>小伶</h4></td>
                                    <td><h2 className="normal">Street Jazz</h2><h4>CEN妹</h4></td>
                                    <td><h2 className="normal">House</h2><h4>小伶</h4></td>
                                    <td><h2 className="normal">FreeStyle</h2><h4>承達</h4></td>
                                    <td><h2 className="started">Street Jazz</h2><h4>卓櫻</h4></td>
                                    <td><h2 className="adv">Hip-Hop</h2><h4>范綱</h4></td>
                                    <td><h2 className="started">Dancehall</h2><h4>沐依</h4></td>
                                    <td><h2 className="started">FreeStyle</h2><h4>奕夫</h4></td>
                                    <td><h2 className="started">Dancehall</h2><h4>虹宇</h4></td>
                                    <td>17:00<br />|<br />18:30</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <ClassThree />
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );
}

export default Class;
