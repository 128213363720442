import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function Location() {
    return (
        <>
            <Tabs>
                <div id="blockName">
                    <div className="inner">
                        <h5 className="title">教室位置</h5>
                        <TabList>
                            <Tab>光復館</Tab>
                            <Tab>公館館</Tab>
                            <Tab>新竹館</Tab>
                            <Tab>Mini</Tab>
                        </TabList>
                    </div>
                </div>
                <TabPanel name='one'>
                    <div className="inner">
                        <h2>台北市光復南路116巷26號B1<br />(02)2775-4033</h2>
                    </div>
                    <a href="https://www.google.com.tw/maps/place/Merry+Monarc+Guangfu/@25.0429501,121.553032,17z/data=!4m5!3m4!1s0x3442abc6dd11b94d:0x288629b98f89ad98!8m2!3d25.0429404!4d121.5563901" target="_blank" rel="noreferrer"><div className="map"></div></a>
                </TabPanel>
                <TabPanel name='two'>
                    <div className="inner">
                        <h2>台北市大安區羅斯福路三段335號B1<br />(02)2366-1144</h2>
                    </div>
                    <a href="https://www.google.com.tw/maps/place/Merry+Monarc+%E8%88%9E%E8%B9%88%E5%B7%A5%E4%BD%9C%E5%AE%A4(%E5%85%AC%E9%A4%A8%E5%BA%97)/@25.0169144,121.5302177,17z/data=!3m1!4b1!4m5!3m4!1s0x3442a98be97ec979:0xd0f9e0ec0cc05c2b!8m2!3d25.0169096!4d121.5324117" target="_blank" rel="noreferrer"><div className="map"></div></a>
                </TabPanel>
                <TabPanel name='three'>
                    <div className="inner">
                        <h2>新竹市林森路46號2樓<br />(03)5240967</h2>
                    </div>
                    <a href="https://www.google.com.tw/maps/place/Merry+Monarc+%E8%88%9E%E8%B9%88%E5%B7%A5%E4%BD%9C%E5%AE%A4/@24.8016913,120.9661889,17z/data=!3m1!4b1!4m5!3m4!1s0x346835ea42eb34c9:0xaae69da4d9be2358!8m2!3d24.8016864!4d120.9683776" target="_blank" rel="noreferrer"><div className="map"></div></a>
                </TabPanel>
                <TabPanel name='four'>
                    <div class="inner">
                        <h2>台北市延吉街87號B1</h2>
                    </div>
                    <a href="https://www.google.com.tw/maps/place/106%E5%8F%B0%E5%8C%97%E5%B8%82%E5%A4%A7%E5%AE%89%E5%8D%80%E5%BB%B6%E5%90%89%E8%A1%9787%E8%99%9FB1/@25.0435248,121.5518451,17z/data=!3m1!4b1!4m5!3m4!1s0x3442abc41e2ce951:0x8e5c586a464043a8!8m2!3d25.04352!4d121.5540338" target="_blank" rel="noreferrer"><div class="map"></div></a>
                </TabPanel>
            </Tabs>
        </>
    );
}

export default Location;