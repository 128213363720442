import React from 'react';

function ClassThree() {
    return (
        <table className="mTable three">
            <thead>
                <tr>
                    <th colSpan="2">Time</th>
                    <td><i>14:30~16:00</i></td>
                    <td><i>16:30~18:00</i></td>
                    <td><i>18:30~20:00</i></td>
                    <td><i>20:00~21:30</i></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th rowSpan="2">一</th>
                    <th>A</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="started">TopRock</h2><h4>阿雅</h4></td>
                    <td><h2 className="started">Hip-Hop</h2><h4>浩浩</h4></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="started">Locking</h2><h4>翔A</h4></td>
                    <td><h2 className="started">基礎律動</h2><h4>小伶</h4></td>
                </tr>
                <tr>
                    <th rowSpan="2">二</th>
                    <th>A</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="normal">HipHop</h2><h4>阿睿</h4></td>
                    <td><h2 className="normal">Street Jazz</h2><h4>CEN妹</h4></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="started">Street Jazz</h2><h4>CoCo</h4></td>
                    <td><h2 className="normal">House</h2><h4>小伶</h4></td>
                </tr>
                <tr>
                    <th rowSpan="2">三</th>
                    <th>A</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="started">Dancehall<br />& Afro</h2><h4>宇麟</h4></td>
                    <td><h2 className="started">Street Jazz</h2><h4>卓櫻</h4></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="started">Popping</h2><h4>阿棠</h4></td>
                    <td><h2 className="normal">FreeStyle</h2><h4>承達</h4></td>
                </tr>
                <tr>
                    <th rowSpan="2">四</th>
                    <th>A</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="started">Street Jazz</h2><h4>沐依</h4></td>
                    <td><h2 className="started">Dancehall</h2><h4>沐依</h4></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="started">基礎律動</h2><h4>小JOY</h4></td>
                    <td><h2 className="adv">Hip-Hop</h2><h4>范綱</h4></td>
                </tr>
                <tr>
                    <th rowSpan="2">五</th>
                    <th>A</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="started">New Jazz</h2><h4>兔兔</h4></td>
                    <td><h2 className="started">FreeStyle</h2><h4>奕夫</h4></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="started">Popping</h2><h4>雪球</h4></td>
                    <td><h2 className="started">Dancehall</h2><h4>虹宇</h4></td>
                </tr>
                <tr className="time">
                    <th></th>
                    <th></th>
                    <th>12:30~14:00</th>
                    <th>14:00~15:30</th>
                    <th>15:30~17:00</th>
                    <th>17:00~18:30</th>
                </tr>
                <tr>
                    <th rowSpan="2">六</th>
                    <th>A</th>
                    <td><h2 className="normal">Street Jazz</h2><h4>伊娜</h4></td>
                    <td><h2 className="started">House</h2><h4>書洋</h4></td>
                    <td><h2 className="started">基礎律動</h2><h4>小茜</h4></td>
                    <td></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td><h2 className="started">基礎律動</h2><h4>書洋</h4></td>
                    <td><h2 className="normal">Locking</h2><h4>古呈允</h4></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th rowSpan="2">日</th>
                    <th>A</th>
                    <td><h2 className="started">基礎律動</h2><h4>小智</h4></td>
                    <td><h2 className="normal">New Jazz</h2><h4>兔兔</h4></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td><h2 className="started">Waacking</h2><h4>卓櫻</h4></td>
                    <td><h2 className="started">Hip-Hop</h2><h4>小智</h4></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    );
}

export default ClassThree;