import React from 'react';

function ClassOne() {
    return (
        <>
            <table className="mTable">
                <thead>
                    <tr>
                        <th colSpan="2">Time</th>
                        <td><i>16:30~18:00</i></td>
                        <td><i>18:30~20:00</i></td>
                        <td><i>20:15~21:45</i></td>
                        <td><i>20:30~22:00</i></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th rowSpan="2">一</th>
                        <th>A</th>
                        <td></td>
                        <td>
                            <h2 className="adv">House</h2>
                            <h4>小穎</h4>
                        </td>
                        <td>
                            <h2 className="normal">Hip-Hop</h2>
                            <h4>Ben</h4>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>B</th>
                        <td></td>
                        <td><h2 className="normal">Waacking</h2><h4>金瑋瑋</h4></td>
                        <td><h2 className="normal">Choreography</h2><h4>七七</h4></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th rowSpan="2">二</th>
                        <th>A</th>
                        <td></td>
                        <td>
                            <h2 className="normal">Hip-Hop</h2>
                            <h4>Mark</h4></td>
                        <td>
                            <h2 className="adv">Hip-Hop</h2>
                            <h4>查理</h4>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>B</th>
                        <td></td>
                        <td><h2 className="normal">High Heels</h2><h4>宋靜</h4></td>
                        <td>
                            <h2 className="adv">Dancehall<br />Reggae</h2>
                            <h4>小姬</h4>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <th rowSpan="2">三</th>
                        <th>A</th>
                        <td></td>
                        <td>
                            <h2 className="normal">Afro<br />FreeStyle</h2>
                            <h4>小姬</h4></td>
                        <td>
                            <h2 className="normal">Hip-Hop</h2>
                            <h4>昱豪</h4></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>B</th>
                        <td></td>
                        <td></td>
                        <td>
                            <h2 className="normal">Popping</h2>
                            <h4>龔妹</h4>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <th rowSpan="2">四</th>
                        <th>A</th>
                        <td></td>
                        <td>
                            <h2 className="adv">FreeStyle</h2>
                            <h4>卡拉</h4>
                        </td>
                        <td>
                            <h2 className="normal">Locking</h2>
                            <h4>鄭妹</h4>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>B</th>
                        <td></td>
                        <td>
                            <h2 className="normal">Girl Style</h2>
                            <h4>冉宥</h4>
                        </td>
                        <td><h2 className="normal">Choreography</h2><h4>小林</h4></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th rowSpan="2">五</th>
                        <th>A</th>
                        <td></td>
                        <td><h2 className="adv">Hip-Hop</h2><h4>家延</h4></td>
                        <td><h2 className="normal">FreeStyle</h2><h4>亨利</h4></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>B</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th rowSpan="2">六</th>
                        <th>A</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>B</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th rowSpan="2">日</th>
                        <th>A</th>
                        <td>
                            <h2 className="normal">House</h2>
                            <h4>牛牛</h4></td>
                        <td>
                            <h2 className="normal">Choreography</h2>
                            <h4>姿儀</h4>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>B</th>
                        <td>
                            <h2 className="normal">Hip-Hop</h2>
                            <h4>胡婷</h4>
                        </td>
                        <td><h2 className="adv">Dancehall</h2><h4>小南</h4></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <img src={require('./assets/images/c-title.png')} className="mcTitle" alt="課程標題" />
            <table className="mTable">
                <thead>
                    <tr className="tr-white">
                        <th>Time</th>
                        <th>一</th>
                        <th>二</th>
                        <th>三</th>
                        <th>四</th>
                        <th>五</th>
                        <th></th>
                        <th>六</th>
                        <th>日</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><i>18:30<br /> ~ <br />20:00</i></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <th>11:00</th>
                        <td><h2 className="started">幼兒律動</h2><h4>青軒</h4></td>
                        <td><h2 className="started">幼兒律動</h2><h4>若若</h4></td>
                    </tr>
                    <tr>
                        <td><i>20:30<br /> ~ <br />22:00</i></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <th>12:00</th>
                        <td><h4>午休</h4></td>
                        <td><h4>午休</h4></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <th>13:30</th>
                        <td>
                            <h2 className="started">MV</h2>
                            <h4>青軒</h4>
                        </td>
                        <td><h2 className="started">Popping</h2><h4>龔妹</h4></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <th>14:45</th>
                        <td><h2 className="started">Hip-Hop</h2><h4>Ben</h4></td>
                        <td><h2 className="started">Hip-Hop</h2><h4>姿儀</h4></td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default ClassOne;