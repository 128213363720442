import React from 'react';

function ClassTwo() {
    return (
        <table className="mTable">
            <thead>
                <tr>
                    <th colSpan="2">Time</th>
                    <td><i>14:30~16:00</i></td>
                    <td><i>16:30~18:00</i></td>
                    <td><i>18:30~20:00</i></td>
                    <td><i>20:30~22:00</i></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th rowSpan="2">一</th>
                    <th>A</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="normal">Hip-Hop</h2><h4>Mark</h4></td>
                    <td><h2 className="started">House</h2><h4>學耀</h4></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="started">Dancehall</h2><h4>小喵</h4></td>
                    <td><h2 className="started">Oldway<br />vogueing</h2><h4>Old Way 234</h4></td>
                </tr>
                <tr>
                    <th rowSpan="2">二</th>
                    <th>A</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="normal">Locking</h2><h4>菜菜</h4></td>
                    <td><h2 className="normal">Voguing</h2><h4>a-Yao</h4></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="normal">Girl Style</h2><h4>小捲</h4></td>
                    <td><h2 className="started">Hip-Hop</h2><h4>青軒</h4></td>
                </tr>
                <tr>
                    <th rowSpan="2">三</th>
                    <th>A</th>
                    <td></td>
                    <td></td>
                    <td>
                        <h2 className="normal">Girl<br />
                            Style</h2><h4>曲莉</h4>
                    </td>
                    <td><h2 className="normal">House</h2><h4>JuAnn</h4></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="started">Hip-Hop</h2><h4>中中</h4></td>
                    <td><h2 className="started">Waacking</h2><h4>芭比</h4></td>
                </tr>
                <tr>
                    <th rowSpan="2">四</th>
                    <th>A</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="normal">Dancehall</h2><h4>金瑋瑋</h4></td>
                    <td><h2 className="adv">Popping</h2><h4>雪球</h4></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="started">Hip-Hop</h2><h4>奶茶</h4></td>
                    <td><h2 className="started">Dancehall</h2><h4>涵涵</h4></td>
                </tr>
                <tr>
                    <th rowSpan="2">五</th>
                    <th>A</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="started">Girl Style</h2><h4>虹宇</h4></td>
                    <td><h2 className="normal">Afro Style</h2><h4>巴豪</h4></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td></td>
                    <td></td>
                    <td><h2 className="started">Locking</h2><h4>冠瑒</h4></td>
                    <td><h2 className="normal">Popping</h2><h4>小胖</h4></td>
                </tr>
                <tr>
                    <th rowSpan="2">六</th>
                    <th>A</th>
                    <td><h2 className="adv">Dancehall</h2><h4>黑嘉麗</h4></td>
                    <td><h2 className="normal">Locking</h2>
                        <h4>Swag</h4></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td><h2 className="started">Waacking</h2><h4>歐陽</h4></td>
                    <td><h2 className="normal">Hip-Hop</h2><h4>可白</h4></td>
                    <td><h2 className="started">Popping</h2><h4>小樂</h4></td>
                    <td></td>
                </tr>
                <tr>
                    <th rowSpan="2">日</th>
                    <th>A</th>
                    <td>
                        <h2 className="started">兒童街舞<br />
                            (7歲以上)</h2>
                        <h4>小龍女</h4><h4>14：30~15：30</h4>
                    </td>
                    <td><h2 className="started">基礎律動</h2><h4>阿睿</h4></td>
                    <td><h2 className="started">Waacking</h2><h4>雅琦</h4></td>
                    <td></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td></td>
                    <td><h2 className="started">Girl Style</h2><h4>默默</h4></td>
                    <td><h2 className="started">Hip-Hop</h2><h4>小龍女</h4></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    );
}

export default ClassTwo;